import i18n from 'i18next'
import {
  initReactI18next
} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import cn from './locales/cn.json'
import pt from './locales/pt.json'
import zhHK from './locales/zh-hk.json'

export const defaultNS = 'translation'
export const resources = {
  cn: {
    translation: cn
  },
  pt: {
    translation: pt
  },
  hk: {
    translation: zhHK
  },
}

export const availableLanguages = Object.keys(resources)

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'hk',
    resources,
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n