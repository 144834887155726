// Library
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

// Custom Library
import './i18n/i18n'

// Style
import './index.css'

// Page
import App from './App'
import { BlockDesktop } from './components/BlockDesktop'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/*<BlockDesktop />*/}
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
