import {
    createSlice
} from '@reduxjs/toolkit'
import * as THREE from 'three';

export const emergingDragonSlice = createSlice({
    name: 'emergingDragon',
    initialState: {
        arContent: {
            position: new THREE.Vector3(0, 0, 0),
            scale: new THREE.Vector3(1, 1, 1),
            rotation: new THREE.Vector3(0, 0, 0)
        }
    },
    reducers: {
        updatePosition: (state, action) => {
            // console.log('action payload', action.payload)
            // const arContent = state.arContent
            // arContent.position = action.payload
            state.arContent = action.payload
        }
    }
})

export const {
    updatePosition
} = emergingDragonSlice.actions

export default emergingDragonSlice.reducer