import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


// Images
import ExploreTheChurchImg from '../../images/card-coloane/cover_explore-the-church.png'
import FightForVictoryImg from '../../images/card-coloane/cover_fight-for-victory.png'
import EnjoyFolkDanceImg from '../../images/card-coloane/cover_enjoy-folk-dance.png'
import ColourfulRoostersImg from '../../images/card-coloane/cover_colourful-roosters.png'
import PortugueseHeaddressImg from '../../images/card-coloane/cover_portuguese-headdress.png'
import callingMakmakImg from '../../images/card-coloane/cover_calling-makmak.png'
import FunnySignageGirlImg from '../../images/card-coloane/cover_funny-signage-girl.png'
import FunnySignageFatImg from '../../images/card-coloane/cover_funny-signage-fat.png'
import TheHiddenTreasureImg from '../../images/card-coloane/cover_the-hidden-treasure.png'

const events = [
  {
    name: '遊天主堂',
    href: '/coloane-macau/explore-the-church',
    slug: 'explore-the-church',
    imageSrc: ExploreTheChurchImg,
    imageAlt: '遊天主堂',
    status: '已完成',
    description: '遊天主堂 - 簡介',
  },
  {
    name: '戰勝海怪',
    href: '/coloane-macau/fight-for-victory',
    slug: 'fight-for-victory',
    imageSrc: FightForVictoryImg,
    imageAlt: '戰勝海怪',
    status: '已完成',
    description: '戰勝海怪 - 簡介',
  },
  {
    name: '賞土風舞',
    href: '/coloane-macau/enjoy-folk-dance',
    slug: 'enjoy-folk-dance',
    imageSrc: EnjoyFolkDanceImg,
    imageAlt: '賞土風舞',
    status: '已完成',
    description: '賞土風舞 - 簡介',
  },
  {
    name: '葡國公雞',
    href: '/coloane-macau/colourful-roosters',
    slug: 'colourful-roosters',
    imageSrc: ColourfulRoostersImg,
    imageAlt: '葡國公雞',
    status: '已完成',
    description: '葡國公雞 - 簡介',
  },
  {
    name: '葡飾魅影',
    href: '/coloane-macau/portuguese-headdress',
    slug: 'portuguese-headdress',
    imageSrc: PortugueseHeaddressImg,
    imageAlt: '葡飾魅影',
    status: '已完成',
    description: '葡飾魅影 - 簡介',
  },
  {
    name: '呼喚麥麥',
    href: '/coloane-macau/calling-makmak',
    slug: 'calling-makmak',
    imageSrc: callingMakmakImg,
    imageAlt: '呼喚麥麥',
    status: '已完成',
    description: '呼喚麥麥 - 簡介',
  },
  {
    name: '趣覓路牌 - 美女巷',
    href: '/coloane-macau/funny-signage-girl',
    slug: 'funny-signage-girl',
    imageSrc: FunnySignageGirlImg,
    imageAlt: '趣覓路牌 - 美女巷',
    status: '已完成',
    description: '趣覓路牌 - 美女巷 - 簡介',
  },
  {
    name: '趣覓路牌 - 肥伴圍',
    href: '/coloane-macau/funny-signage-fat',
    slug: 'funny-signage-fat',
    imageSrc: FunnySignageFatImg,
    imageAlt: '趣覓路牌 - 肥伴圍',
    status: '已完成',
    description: '趣覓路牌 - 肥伴圍 - 簡介',
  },
  {
    name: '鎮店之寶',
    href: '/coloane-macau/the-hidden-treasure',
    slug: 'the-hidden-treasure',
    imageSrc: TheHiddenTreasureImg,
    imageAlt: '鎮店之寶',
    status: '已完成',
    description: '鎮店之寶 - 簡介',
  },
]

function ColoaneMacau() {
  const { i18n, t } = useTranslation()

  function statusColor(status) {
    if (status === '等待中') {
      return 'bg-gray-100 text-gray-600'
    } else if (status === '制作中') {
      return 'bg-blue-100 text-blue-600'
    } else if (status === '待優化') {
      return 'bg-purple-100 text-purple-600'
    } else if (status === '待處理') {
      return 'bg-yellow-100 text-yellow-600'
    } else if (status === '待更新') {
      return 'bg-red-100 text-red-600'
    } else if (status === '已完成') {
      return 'bg-green-100 text-green-600'
    }
  }

  return (
    <div className='bg-white'>
      <div className='p-4'>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('hk')}>
          繁
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('cn')}>
          简
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('pt')}>
          PT
        </button>
      </div>

      <div className='max-w-xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <Link to={'/'} className={'rounded border px-4 py-2 mb-8'}>返回</Link>
        <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 mt-8'>悠路環</h2>
        <p className='mt-4 text-base text-gray-500'>{t('description')}</p>

        <div className='mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
          {events.map((event) => {
            return (
              <Link
                key={event.name}
                to={event.href}
                className='group block border rounded-lg overflow-hidden'
              >
                <div
                  aria-hidden='true'
                  className='aspect-w-3 aspect-h-2 overflow-hidden group-hover:opacity-75 lg:aspect-w-3 lg:aspect-h-2'
                >
                  <img
                    src={event.imageSrc}
                    alt={event.imageAlt}
                    className='w-full h-full object-center object-cover'
                  />
                </div>

                <div className='p-4'>
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    'inline-flex items-center px-2.5 pt-1 pb-0.5 rounded text-sm font-medium ' +*/}
                  {/*    statusColor(event.status)*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {event.status}*/}
                  {/*</span>*/}
                  <h3 className='my-2 text-base font-semibold text-gray-900'>{t(event.name)}</h3>
                  <p className='text-sm text-gray-500'>{t(event.description)}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ColoaneMacau
