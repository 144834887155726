import QRCode from 'react-qr-code'

export const BlockDesktop = (props) => (
  <div
    id='block-desktop'
    className='fixed inset-0 flex bg-taipa-lightblue items-center justify-center'
    style={{
      zIndex: 100,
      backgroundImage: `url(images/tapia-image/desktop-bg.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className='text-center container mx-auto mb-8'>
      {/* Logo */}
      <img src='images/tapia-image/logo-s.png' className='mx-auto w-60 mb-16' />

      {/* QR Code */}
      <div className='md:inline-block p-2 none rounded-xl mx-auto my-16 border-8 border-taipa-darkblue bg-white'>
        <QRCode size={256} value={props.url} />
      </div>

      <div className='md:hidden p-2 inline-block rounded-xl mx-auto my-16 border-8 border-taipa-darkblue bg-white'>
        <QRCode size={200} value={props.url} />
      </div>

      {/* Content   */}
      <div className='px-4'>
        <p className='text-white font-medium text-lg'>
          請使用手機掃描QR code 或<br />
          複製以下網址並於手機瀏覽器中開啟
        </p>
        <input
          className='bg-white border-0 rounded text-gray-700 p-4 my-4 w-full max-w-xl mx-auto focus:outline-rua-red'
          defaultValue={props.url}
        />
      </div>
    </div>

    <small className='absolute bottom-12 block text-center text-xs text-white'>
      &copy; Copyright 2022 悠氹仔, all rights reserved.
    </small>
  </div>
)
