import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const projects = [
  {
    name: '關前薈',
    href: '/ervanarios-macau',
    slug: 'ervanarios-macau',
    imageSrc: '/images/ervanarios-image/cover.jpg',
    imageAlt: '關前薈',
    status: '已完成',
    description: '2021-09-01 ~ 2023-02-05',
  },
  {
    name: '悠路環',
    href: '/coloane-macau',
    slug: 'coloane-macau',
    imageSrc: '/images/coloane-image/cover.png',
    imageAlt: '悠路環',
    status: '已完成',
    description: '2022-05-01 ~ 2023-02-05',
  },
  {
    name: '悠氹仔',
    href: '/taipa-macau',
    slug: 'taipa-macau',
    imageSrc: '/images/tapia-image/cover.png',
    imageAlt: '悠氹仔',
    status: '已完成',
    description: '2022-10-01 ~ 2023-02-05',
  },
]

function Home() {
  const { i18n, t } = useTranslation()

  function statusColor(status) {
    if (status === '等待中') {
      return 'bg-gray-100 text-gray-600'
    } else if (status === '制作中') {
      return 'bg-blue-100 text-blue-600'
    } else if (status === '待優化') {
      return 'bg-purple-100 text-purple-600'
    } else if (status === '待處理') {
      return 'bg-yellow-100 text-yellow-600'
    } else if (status === '待更新') {
      return 'bg-red-100 text-red-600'
    } else if (status === '已完成') {
      return 'bg-green-100 text-green-600'
    }
  }

  return (
    <div className='bg-white'>
      <div className='p-4'>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('hk')}>
          繁
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('cn')}>
          简
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('pt')}>
          PT
        </button>
      </div>

      <div className='max-w-xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <h2 className='text-4xl font-extrabold tracking-tight text-gray-900'>AR Projects</h2>
        <p className='mt-4 text-base text-gray-500'>{t('description')}</p>

        <div className='mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
          {projects.map((project) => {
            return (
              <Link
                key={project.name}
                to={project.href}
                className='group block border rounded-lg overflow-hidden'
              >
                <div
                  aria-hidden='true'
                  className='aspect-w-3 aspect-h-2 overflow-hidden group-hover:opacity-75 lg:aspect-w-3 lg:aspect-h-2'
                >
                  <img
                    src={project.imageSrc}
                    alt={project.imageAlt}
                    className='w-full h-full object-center object-cover'
                  />
                </div>

                <div className='p-4'>
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    'inline-flex items-center px-2.5 pt-1 pb-0.5 rounded text-sm font-medium ' +*/}
                  {/*    statusColor(project.status)*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {project.status}*/}
                  {/*</span>*/}
                  <h3 className='my-2 text-base font-semibold text-gray-900'>{t(project.name)}</h3>
                  <p className='text-sm text-gray-500'>{t(project.description)}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Home
