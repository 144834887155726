import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Images
import bloomingFlowersImg from '../../images/cover/banner_blooming-flowers.jpg'
import portugueseSardinesImg from '../../images/cover/banner_portuguese-sardines.jpg'
import storyOnBrickImg from '../../images/cover/banner_story-on-brick.jpg'
import EmergingDragonImg from '../../images/cover/banner_emerging-dragon.jpg'
import leapingTheGateImg from '../../images/cover/banner_leaping-the-gate.jpg'
import eletricosDeLisboaImg from '../../images/cover/banner_eletricos-de-lisboa.jpg'
import grabTheGourmetImg from '../../images/cover/banner_grab-the-gourmet.jpg'
import callingMakmakImg from '../../images/cover/banner_calling-makmak.jpg'
import backToHistoryImg from '../../images/cover/banner_back-to-history.jpg'
import theSkyLanternImg from '../../images/cover/banner_the-sky-lantern.jpg'
import welcomeToTaipaImg from '../../images/cover/banner_welcome-to-taipa.jpg'
import gastronmiaMacaenseImg from '../../images/cover/banner_gastronomia-macaense.jpg'

const events = [
  {
    name: '花樣年華',
    href: '/taipa-macau/blooming-flowers',
    slug: 'blooming-flowers',
    imageSrc: bloomingFlowersImg,
    imageAlt: '花樣年華',
    status: '已完成',
    description: '花樣年華 - 簡介',
  },
  {
    name: '魚樂無窮',
    href: '/taipa-macau/portuguese-sardines',
    slug: 'portuguese-sardines',
    imageSrc: portugueseSardinesImg,
    imageAlt: '魚樂無窮',
    status: '待優化',
    description: '魚樂無窮 - 簡介',
  },
  {
    name: '葡磚浮畫',
    href: '/taipa-macau/story-on-brick',
    slug: 'story-on-brick',
    imageSrc: storyOnBrickImg,
    imageAlt: '葡磚浮畫',
    status: '等待中',
    description: '葡磚浮畫 - 簡介',
  },
  {
    name: '龍騰而出',
    href: '/taipa-macau/emerging-dragon',
    slug: 'emerging-dragon',
    imageSrc: EmergingDragonImg,
    imageAlt: '龍騰而出',
    status: '已完成',
    description: '龍騰而出 - 簡介',
  },
  {
    name: '魚躍龍門',
    href: '/taipa-macau/leaping-the-gate',
    slug: 'leaping-the-gate',
    imageSrc: leapingTheGateImg,
    imageAlt: '魚躍龍門',
    status: '待優化',
    description: '魚躍龍門 - 簡介',
  },
  {
    name: '葡國電車',
    href: '/taipa-macau/eletricos-de-lisboa',
    slug: 'eletricos-de-lisboa',
    imageSrc: eletricosDeLisboaImg,
    imageAlt: '葡國電車',
    status: '等待中',
    description: '葡國電車 - 簡介',
  },
  {
    name: '巧奪葡食',
    href: '/taipa-macau/grab-the-gourmet',
    slug: 'grab-the-gourmet',
    imageSrc: grabTheGourmetImg,
    imageAlt: '巧奪葡食',
    status: '制作中',
    description: '巧奪葡食 - 簡介',
  },
  {
    name: '呼喚麥麥',
    href: '/taipa-macau/calling-makmak',
    slug: 'calling-makmak',
    imageSrc: callingMakmakImg,
    imageAlt: '呼喚麥麥',
    status: '已完成',
    description: '呼喚麥麥 - 簡介',
  },
  {
    name: '回到舊墟',
    href: '/taipa-macau/back-to-history',
    slug: 'back-to-history',
    imageSrc: backToHistoryImg,
    imageAlt: '回到舊墟',
    status: '等待中',
    description: '回到舊墟 - 簡介',
  },
  {
    name: '祈願天燈',
    href: '/taipa-macau/the-sky-lantern',
    slug: 'the-sky-lantern',
    imageSrc: theSkyLanternImg,
    imageAlt: '祈願天燈',
    status: '待優化',
    description: '祈願天燈 - 簡介',
  },
  {
    name: '悠氹之美',
    href: '/taipa-macau/welcome-to-taipa',
    slug: 'welcome-to-taipa',
    imageSrc: welcomeToTaipaImg,
    imageAlt: '悠氹之美',
    status: '待處理',
    description: '悠氹之美 - 簡介',
  },
  {
    name: '悠煮葡菜',
    href: '/taipa-macau/gastronomia-macaense',
    slug: 'gastronomia-macaense',
    imageSrc: gastronmiaMacaenseImg,
    imageAlt: '悠煮葡菜',
    status: '等待中',
    description: '悠煮葡菜 - 簡介',
  },
]

function TaipaMacau() {
  const { i18n, t } = useTranslation()

  function statusColor(status) {
    if (status === '等待中') {
      return 'bg-gray-100 text-gray-600'
    } else if (status === '制作中') {
      return 'bg-blue-100 text-blue-600'
    } else if (status === '待優化') {
      return 'bg-purple-100 text-purple-600'
    } else if (status === '待處理') {
      return 'bg-yellow-100 text-yellow-600'
    } else if (status === '待更新') {
      return 'bg-red-100 text-red-600'
    } else if (status === '已完成') {
      return 'bg-green-100 text-green-600'
    }
  }

  return (
    <div className='bg-white'>
      <div className='p-4'>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('hk')}>
          繁
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('cn')}>
          简
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('pt')}>
          PT
        </button>
      </div>

      <div className='max-w-xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <Link to={'/'} className={'rounded border px-4 py-2 mb-8'}>返回</Link>
        <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 mt-8'>{t('title')}</h2>
        <p className='mt-4 text-base text-gray-500'>{t('description')}</p>

        <div className='mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
          {events.map((event) => {
            return (
              <Link
                key={event.name}
                to={event.href}
                className='group block border rounded-lg overflow-hidden'
              >
                <div
                  aria-hidden='true'
                  className='aspect-w-3 aspect-h-2 overflow-hidden group-hover:opacity-75 lg:aspect-w-3 lg:aspect-h-2'
                >
                  <img
                    src={event.imageSrc}
                    alt={event.imageAlt}
                    className='w-full h-full object-center object-cover'
                  />
                </div>

                <div className='p-4'>
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    'inline-flex items-center px-2.5 pt-1 pb-0.5 rounded text-sm font-medium ' +*/}
                  {/*    statusColor(event.status)*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {event.status}*/}
                  {/*</span>*/}
                  <h3 className='my-2 text-base font-semibold text-gray-900'>{t(event.name)}</h3>
                  <p className='text-sm text-gray-500'>{t(event.description)}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TaipaMacau
