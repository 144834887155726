import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Images
import CallingMakmakErvanarios from '../../images/card-coloane/cover_calling-makmak.png'
import CaptureTheMomentErvanarios from '../../images/cover-ervanarios/cover_capture-the-moment.png'
import FlowerErvanarios from '../../images/cover-ervanarios/cover_flower.png'
// import GlimpseOfHistoryErvanarios from '../../images/card-coloane/cover_glimpse-of-history.png'
import PortalToThePastErvanarios from '../../images/cover-ervanarios/cover_portal-to-the-past.png'
// import SouvenirGiveawayErvanarios from '../../images/card-coloane/cover_souvenir-giveaway.png'
// import SurprisingHaircutErvanarios from '../../images/card-coloane/cover_surprising-haircut.png'
import TheHiddenTreasureErvanarios from '../../images/cover-ervanarios/cover_the-hidden-treasure.png'

const events = [
  {
    name: '呼喚麥麥',
    href: '/ervanarios-macau/calling-makmak',
    slug: 'calling-makmak',
    imageSrc: CallingMakmakErvanarios,
    imageAlt: '呼喚麥麥',
    status: '已完成',
    description: '呼喚麥麥 - 簡介',
  },
  {
    name: '逝水年華',
    href: '/ervanarios-macau/capture-the-moment',
    slug: 'capture-the-moment',
    imageSrc: CaptureTheMomentErvanarios,
    imageAlt: '逝水年華',
    status: '已完成',
    description: '逝水年華 - 簡介',
  },
  {
    name: '繁花綻放',
    href: '/ervanarios-macau/flower',
    slug: 'flower',
    imageSrc: FlowerErvanarios,
    imageAlt: '繁花綻放',
    status: '已完成',
    description: '繁花綻放 - 簡介',
  },
  {
    name: '時空之窗',
    href: '/ervanarios-macau/glimpse-of-history',
    slug: 'glimpse-of-history',
    // imageSrc: GlimpseOfHistoryErvanarios,
    imageAlt: '時空之窗',
    status: '已完成',
    description: '花樣年華 - 簡介',
  },
  {
    name: '穿越時空',
    href: '/ervanarios-macau/portal',
    slug: 'portal',
    imageSrc: PortalToThePastErvanarios,
    imageAlt: '穿越時空',
    status: '已完成',
    description: '穿越時空 - 簡介',
  },
  {
    name: '伴手有禮',
    href: '/ervanarios-macau/souvenir-giveaway',
    slug: 'souvenir-giveaway',
    // imageSrc: SouvenirGiveawayErvanarios,
    imageAlt: '伴手有禮',
    status: '已完成',
    description: '伴手有禮 - 簡介',
  },
  {
    name: '俊秀理髮',
    href: '/ervanarios-macau/surprising-haircut',
    slug: 'surprising-haircut',
    // imageSrc: SurprisingHaircutErvanarios,
    imageAlt: '俊秀理髮',
    status: '已完成',
    description: '俊秀理髮 - 簡介',
  },

  {
    name: '鎮店之寶',
    href: '/ervanarios-macau/the-hidden-treasure',
    slug: 'the-hidden-treasure',
    imageSrc: TheHiddenTreasureErvanarios,
    imageAlt: '鎮店之寶',
    status: '已完成',
    description: '鎮店之寶 - 簡介',
  },
]

function ErvanariosMacau() {
  const { i18n, t } = useTranslation()

  function statusColor(status) {
    if (status === '等待中') {
      return 'bg-gray-100 text-gray-600'
    } else if (status === '制作中') {
      return 'bg-blue-100 text-blue-600'
    } else if (status === '待優化') {
      return 'bg-purple-100 text-purple-600'
    } else if (status === '待處理') {
      return 'bg-yellow-100 text-yellow-600'
    } else if (status === '待更新') {
      return 'bg-red-100 text-red-600'
    } else if (status === '已完成') {
      return 'bg-green-100 text-green-600'
    }
  }

  return (
    <div className='bg-white'>
      <div className='p-4'>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('hk')}>
          繁
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('cn')}>
          简
        </button>
        <button className='rounded border mr-2 px-4 py-2' onClick={() => i18n.changeLanguage('pt')}>
          PT
        </button>
      </div>

      <div className='max-w-xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <Link to={'/'} className={'rounded border px-4 py-2 mb-8'}>返回</Link>
        <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 mt-8'>關前薈</h2>
        <p className='mt-4 text-base text-gray-500'>{t('description')}</p>

        <div className='mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
          {events.map((event) => {
            return (
              <Link
                key={event.name}
                to={event.href}
                className='group block border rounded-lg overflow-hidden'
              >
                <div
                  aria-hidden='true'
                  className='aspect-w-3 aspect-h-2 overflow-hidden group-hover:opacity-75 lg:aspect-w-3 lg:aspect-h-2'
                >
                  <img
                    src={event.imageSrc}
                    alt={event.imageAlt}
                    className='w-full h-full object-center object-cover'
                  />
                </div>

                <div className='p-4'>
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    'inline-flex items-center px-2.5 pt-1 pb-0.5 rounded text-sm font-medium ' +*/}
                  {/*    statusColor(event.status)*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {event.status}*/}
                  {/*</span>*/}
                  <h3 className='my-2 text-base font-semibold text-gray-900'>{t(event.name)}</h3>
                  <p className='text-sm text-gray-500'>{t(event.description)}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ErvanariosMacau
