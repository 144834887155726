// Library
import { Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/index'
import React from 'react'

// Page
import Home from './pages/index'
import { LoadingPage } from './components/LoadingPage'

// 關前薈
import ErvanariosMacau from './pages/ervanarios-macau'
const CallingMakmakErvanarios = React.lazy(() => import('./pages/ervanarios-macau/calling-makmak/index')) // 呼喚麥麥
const CaptureTheMomentErvanarios = React.lazy(() => import('./pages/ervanarios-macau/capture-the-moment/index')) // 逝水年華
const FlowerErvanarios = React.lazy(() => import('./pages/ervanarios-macau/flower/index')) // 繁花綻放
const GlimpseOfHistoryErvanarios = React.lazy(() => import('./pages/ervanarios-macau/glimpse-of-history/index')) // 時空之窗
const PortalToThePastErvanarios = React.lazy(() => import('./pages/ervanarios-macau/portal/index')) // 穿越時空
const SouvenirGiveawayErvanarios = React.lazy(() => import('./pages/ervanarios-macau/souvenir-giveaway/index')) // 伴手有禮
const SurprisingHaircutErvanarios = React.lazy(() => import('./pages/ervanarios-macau/surprising-haircut/index')) // 俊秀理髮
const TheHiddenTreasureErvanarios = React.lazy(() => import('./pages/ervanarios-macau/the-hidden-treasure/index')) // 鎮店之寶

// 悠路環
import ColoaneMacau from './pages/coloane-macau'
const CallingMakmakColoane = React.lazy(() => import('./pages/coloane-macau/calling-makmak/index')) // 呼喚麥麥
const ColourfulRoostersColoane= React.lazy(() => import('./pages/coloane-macau/colourful-roosters/index')) // 葡國公雞
const ExploreTheChurchColoane= React.lazy(() => import('./pages/coloane-macau/explore-the-church/index')) // 遊天主堂
const FightForVictoryColoane= React.lazy(() => import('./pages/coloane-macau/fight-for-victory/index')) // 戰勝海怪
const EnjoyFolkDanceColoane= React.lazy(() => import('./pages/coloane-macau/enjoy-folk-dance/index')) // 賞土風舞
const PortugueseHeaddressColoane= React.lazy(() => import('./pages/coloane-macau/portuguese-headdress/index')) // 葡飾魅影
const FunnySignageGirlColoane= React.lazy(() => import('./pages/coloane-macau/funny-signage-girl/index')) // 趣覓路牌 - 美女巷
const FunnySignageFatColoane= React.lazy(() => import('./pages/coloane-macau/funny-signage-fat/index')) // 趣覓路牌 - 肥伴圍
const TheHiddenTreasureColoane= React.lazy(() => import('./pages/coloane-macau/the-hidden-treasure/index')) // 鎮店之寶



// 悠氹仔
import TaipaMacau from './pages/taipa-macau'
import Cny2023 from './pages/taipa-macau/cny2023'
const BloomingFlowers = React.lazy(() => import('./pages/taipa-macau/blooming-flowers/index')) // 花樣年華
const PortugueseSardines = React.lazy(() => import('./pages/taipa-macau/portuguese-sardines/index')) // 葡磚浮畫
const EmergingDragon = React.lazy(() => import('./pages/taipa-macau/emerging-dragon/index')) // 龍騰而出
const LeapingTheGate = React.lazy(() => import('./pages/taipa-macau/leaping-the-gate/index')) // 魚躍龍門
const EletricosDeLisboa = React.lazy(() => import('./pages/taipa-macau/eletricos-de-lisboa/index')) // 葡國電車
const GrabTheGourmet = React.lazy(() => import('./pages/taipa-macau/grab-the-gourmet/index')) // 巧奪葡食
const CallingMakmak = React.lazy(() => import('./pages/taipa-macau/calling-makmak/index')) // 呼喚麥麥
const BackToHistory = React.lazy(() => import('./pages/taipa-macau/back-to-history/index')) // 回到舊墟
const TheSkyLantern = React.lazy(() => import('./pages/taipa-macau/the-sky-lantern/index')) // 祈願天燈
const WelcomeToTaipa = React.lazy(() => import('./pages/taipa-macau/welcome-to-taipa/index')) // 悠氹之美
const StoryOnBrick = React.lazy(() => import('./pages/taipa-macau/story-on-brick/index')) // '葡磚浮畫
const GastronmiaMacaense = React.lazy(() => import('./pages/taipa-macau/gastronomia-macaense/index')) // 悠煮葡菜
const Bacalhau = React.lazy(() => import('./pages/taipa-macau/gastronomia-macaense/bacalhau/index')) // 悠煮葡菜 - 薯絲馬介休
const Pudding = React.lazy(() => import('./pages/taipa-macau/gastronomia-macaense/pudding/index')) // 悠煮葡菜 - 木糠布丁
const Tart = React.lazy(() => import('./pages/taipa-macau/gastronomia-macaense/tart/index')) // 悠煮葡菜 - 蛋撻

// 悠氹仔 - CNY 2023
const CNY2023EmergingDragon = React.lazy(() => import('./pages/taipa-macau/cny2023/emerging-dragon/index')) // 龍騰而出
const CNY2023EletricosDeLisboa = React.lazy(() =>
  import('./pages/taipa-macau/cny2023/eletricos-de-lisboa/index'),
) // 葡國電車
const CNY2023GrabTheGourmet = React.lazy(() => import('./pages/taipa-macau/cny2023/grab-the-gourmet/index')) // 巧奪葡食
const CNY2023CallingMakmak = React.lazy(() => import('./pages/taipa-macau/cny2023/calling-makmak/index')) // 呼喚麥麥
const CNY2023TheSkyLantern = React.lazy(() => import('./pages/taipa-macau/cny2023/the-sky-lantern/index')) // 祈願天燈
const CNY2023WelcomeToTaipa = React.lazy(() => import('./pages/taipa-macau/cny2023/welcome-to-taipa/index')) // 悠氹之美

function App() {
  // Return the App component.
  return (
    <div className='App'>
      <Provider store={store}>
        <Routes>
          <Route path='/' element={<Home />} />

            {/*  ======== 關前薈 ========== */}
            <Route path='/ervanarios-macau' element={<ErvanariosMacau />} />
            {/* 呼喚麥麥 */}
            <Route
            path='/ervanarios-macau/calling-makmak'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CallingMakmakErvanarios />
              </React.Suspense>
            }
            />
            {/* 逝水年華 */}
            <Route
            path='/ervanarios-macau/capture-the-moment'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CaptureTheMomentErvanarios />
              </React.Suspense>
            }
            />
            {/* 繁花綻放 */}
            <Route
            path='/ervanarios-macau/flower'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <FlowerErvanarios />
              </React.Suspense>
            }
            />
            {/* 時空之窗 */}
            <Route
            path='/ervanarios-macau/glimpse-of-history'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <GlimpseOfHistoryErvanarios />
              </React.Suspense>
            }
            />
            {/* 穿越時空 */}
            <Route
            path='/ervanarios-macau/cportal-to-the-past'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <PortalToThePastErvanarios />
              </React.Suspense>
            }
            />
            {/* 伴手有禮 */}
            <Route
            path='/ervanarios-macau/souvenir-giveaway'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <SouvenirGiveawayErvanarios />
              </React.Suspense>
            }
            />
            {/* 俊秀理髮 */}
            <Route
            path='/ervanarios-macau/surprising-haircut'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <SurprisingHaircutErvanarios />
              </React.Suspense>
            }
            />
            {/* 鎮店之寶 */}
            <Route
            path='/ervanarios-macau/the-hidden-treasure'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <TheHiddenTreasureErvanarios />
              </React.Suspense>
            }
            />



            {/*  ======== 悠路環 ========== */}
            <Route path='/coloane-macau' element={<ColoaneMacau />} />
            {/* 呼喚麥麥 */}
            <Route
            path='/coloane-macau/calling-makmak'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CallingMakmakColoane />
              </React.Suspense>
            }
            />
            {/* 葡國公雞 */}
            <Route
            path='/coloane-macau/colourful-roosters'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <ColourfulRoostersColoane />
              </React.Suspense>
            }
            />
            {/* 遊天主堂 */}
            <Route
            path='/coloane-macau/explore-the-church'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <ExploreTheChurchColoane />
              </React.Suspense>
            }
            />
            {/* 戰勝海怪 */}
            <Route
            path='/coloane-macau/fight-for-victory'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <FightForVictoryColoane />
              </React.Suspense>
            }
            />
            {/* 賞土風舞 */}
            <Route
            path='/coloane-macau/enjoy-folk-dance'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <EnjoyFolkDanceColoane />
              </React.Suspense>
            }
            />
            {/* 葡飾魅影 */}
            <Route
            path='/coloane-macau/portuguese-headdress'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <PortugueseHeaddressColoane />
              </React.Suspense>
            }
            />
            {/* 趣覓路牌 - 美女巷 */}
            <Route
            path='/coloane-macau/funny-signage-girl'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <FunnySignageGirlColoane />
              </React.Suspense>
            }
            />
            {/* 趣覓路牌 - 肥伴圍 */}
            <Route
            path='/coloane-macau/funny-signage-fat'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <FunnySignageFatColoane />
              </React.Suspense>
            }
            />

            {/* 鎮店之寶 */}
            <Route
            path='/coloane-macau/the-hidden-treasure'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <TheHiddenTreasureColoane />
              </React.Suspense>
            }
            />


            {/*  ======== 悠氹仔 ========== */}
          <Route path='/taipa-macau' element={<TaipaMacau />} />
          <Route path='/taipa-macau/cny2023' element={<Cny2023 />} />
          {/* 花樣年華 */}
          <Route
            path='/taipa-macau/blooming-flowers'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <BloomingFlowers />
              </React.Suspense>
            }
          />
          {/* 葡磚浮畫 */}
          <Route
            path='/taipa-macau/portuguese-sardines'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <PortugueseSardines />
              </React.Suspense>
            }
          />
          {/* 龍騰而出 */}
          <Route
            path='/taipa-macau/emerging-dragon'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <EmergingDragon />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/emerging-dragon'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023EmergingDragon />
              </React.Suspense>
            }
          />
          {/* 魚躍龍門 */}
          <Route
            path='/taipa-macau/leaping-the-gate'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <LeapingTheGate />
              </React.Suspense>
            }
          />
          {/* 葡國電車 */}
          <Route
            path='/taipa-macau/eletricos-de-lisboa'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <EletricosDeLisboa />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/eletricos-de-lisboa'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023EletricosDeLisboa />
              </React.Suspense>
            }
          />
          {/* '葡磚浮畫 */}
          <Route
            path='/taipa-macau/story-on-brick'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <StoryOnBrick />
              </React.Suspense>
            }
          />
          {/* 巧奪葡食 */}
          <Route
            path='/taipa-macau/grab-the-gourmet'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <GrabTheGourmet />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/grab-the-gourmet'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023GrabTheGourmet />
              </React.Suspense>
            }
          />
          {/* 呼喚麥麥 */}
          <Route
            path='/taipa-macau/calling-makmak'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CallingMakmak />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/calling-makmak'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023CallingMakmak />
              </React.Suspense>
            }
          />
          {/* 回到舊墟 */}
          <Route
            path='/taipa-macau/back-to-history'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <BackToHistory />
              </React.Suspense>
            }
          />
          {/* 祈願天燈 */}
          <Route
            path='/taipa-macau/the-sky-lantern'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <TheSkyLantern />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/the-sky-lantern'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023TheSkyLantern />
              </React.Suspense>
            }
          />
          {/* 悠氹之美*/}
          <Route
            path='/taipa-macau/welcome-to-taipa'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <WelcomeToTaipa />
              </React.Suspense>
            }
          />
          <Route
            path='/taipa-macau/cny2023/welcome-to-taipa'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <CNY2023WelcomeToTaipa />
              </React.Suspense>
            }
          />
          {/* 悠煮葡菜 */}
          <Route
            path='/taipa-macau/gastronomia-macaense'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <GastronmiaMacaense />
              </React.Suspense>
            }
          />

          {/* 悠煮葡菜 - 薯絲馬介休 */}
          <Route
            path='/taipa-macau/gastronomia-macaense/bacalhau'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Bacalhau />
              </React.Suspense>
            }
          />

          {/* 悠煮葡菜 - 木糠布丁 */}
          <Route
            path='/taipa-macau/gastronomia-macaense/pudding'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Pudding />
              </React.Suspense>
            }
          />

          {/* 悠煮葡菜 - 葡撻 */}
          <Route
            path='/taipa-macau/gastronomia-macaense/tart'
            element={
              <React.Suspense fallback={<LoadingPage />}>
                <Tart />
              </React.Suspense>
            }
          />
        </Routes>
      </Provider>
    </div>
  )
}

export default App
